// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-podcast-tsx": () => import("../src/templates/podcast.tsx" /* webpackChunkName: "component---src-templates-podcast-tsx" */),
  "component---src-templates-blog-entry-tsx": () => import("../src/templates/blog-entry.tsx" /* webpackChunkName: "component---src-templates-blog-entry-tsx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestellliste-tsx": () => import("../src/pages/bestellliste.tsx" /* webpackChunkName: "component---src-pages-bestellliste-tsx" */),
  "component---src-pages-blog-tsx": () => import("../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meine-leidenschaften-tsx": () => import("../src/pages/meine-leidenschaften.tsx" /* webpackChunkName: "component---src-pages-meine-leidenschaften-tsx" */),
  "component---src-pages-mobile-dev-tsx": () => import("../src/pages/mobile-dev.tsx" /* webpackChunkName: "component---src-pages-mobile-dev-tsx" */),
  "component---src-pages-podcast-archiv-tsx": () => import("../src/pages/podcast/archiv.tsx" /* webpackChunkName: "component---src-pages-podcast-archiv-tsx" */),
  "component---src-pages-wn-tsx": () => import("../src/pages/wn.tsx" /* webpackChunkName: "component---src-pages-wn-tsx" */)
}

